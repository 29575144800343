import React, { useState } from "react";
import Seo from "../components/SEO/SEO";
import Container from "../components/Container/Container";
import Helmet from "react-helmet";
import InView from "../components/InView";
import { Link } from "gatsby";

import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import { useForm } from "react-hook-form";
import axios from "axios";

const Tennants = () => {
  return (
    <>
      <Seo title="Aria Tenant" />
      <Helmet
        bodyAttributes={{
          class: "no-banner",
        }}
      >
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        <div className="border-b border-grey-default box-border min-h-[80vh]">
          <InView>
            <div className="container flex md:grid md:grid-cols-12 mt-[88px]">
              <div className="md:col-span-9 xl:col-span-7 pb-10 md:pb-[120px]">
                <article className="copy md:pr-10">
                  <h1 className="font-serif text-[32px] leading-[1.2]">
                    Be part of the Aria Family
                  </h1>
                </article>
                <TenantForm />
              </div>
            </div>
          </InView>
        </div>
      </Container>
    </>
  );
};

const TenantForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
  });

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
  };
  const handleOnSubmit = (data, e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });

    const formData = new FormData(form);
    formData.append(
      "g-recaptcha-response",
      serverState["g-recaptcha-response"]
    );

    axios({
      method: "post",
      url: "https://getform.io/f/240841c8-2d23-4c57-b974-78e013ca47bd",
      data: formData,
    })
      .then((r) => {
        handleServerResponse(true);
      })
      .catch((r) => {
        handleServerResponse(false);
      });
  };

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;

  const onVerify = React.useCallback(
    (token) => {
      setServerState((prevState) => ({
        ...prevState,
        "g-recaptcha-response": token,
      }));
    },
    [setServerState]
  );

  return (
    <>
      <>
        {serverState.status && serverState.status.ok ? (
          <>
            <div className="md:max-w-[800px]">
              <h3 className="text-[21px] leading-[27.31px] mb-6 font-serif">
                Thank you for your enquiry
              </h3>
              <p>
                A member of the Aria team will be in touch shortly. We
                appreciate your patience.
              </p>
            </div>
          </>
        ) : (
          <>
            <h3 className="text-[21px] leading-[27.31px] mt-6 font-serif">
              Please fill out the below details and a member of our team will
              get back to you shortly.
            </h3>
            <form
              className="w-full max-w-[800px] my-3"
              onSubmit={handleSubmit(handleOnSubmit)}
            >
              <div className="md:flex md:gap-4">
                <input
                  placeholder="First Name*"
                  {...register("firstName", { required: true })}
                  className={`form-control rounded-none border-b border-grey-default font-sans text-newsletter placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
                    errors.firstName ? "border-red-500" : ""
                  }`}
                />
                <input
                  placeholder="Last Name*"
                  {...register("lastName", { required: true })}
                  className={`form-control rounded-none border-b border-grey-default font-sans text-newsletter placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
                    errors.lastName ? "border-red-500" : ""
                  }`}
                />
              </div>
              <div>
                <input
                  placeholder="Email*"
                  {...register("email", {
                    required: true,
                    pattern: emailRegex,
                  })}
                  className={`form-control rounded-none border-b border-grey-default font-sans text-newsletter placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
                    errors.email ? "border-red-500" : ""
                  }`}
                />
              </div>
              <div className="md:flex md:gap-4">
                <input
                  type="text"
                  placeholder="Phone*"
                  {...register("phone", {
                    required: true,
                  })}
                  className={`form-control rounded-none border-b border-grey-default font-sans text-newsletter placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
                    errors.phone ? "border-red-500" : ""
                  }`}
                />
                <input
                  type="text"
                  placeholder="Post Code*"
                  {...register("postCode", {
                    required: true,
                  })}
                  className={`form-control rounded-none border-b border-grey-default font-sans text-newsletter placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
                    errors.postCode ? "border-red-500" : ""
                  }`}
                />
              </div>
              <div>
                <textarea
                  type="text"
                  name="message"
                  placeholder="Comments"
                  className={`form-control rounded-none border-b border-grey-default font-sans text-newsletter placeholder-black my-2 lg:my-3 bg-transparent w-full h-20 lg:h-28 resize-none focus:outline-none`}
                />
              </div>
              <div>
                <label className="inline-flex items-center cursor-pointer mt-3 mb-6">
                  <input type="hidden" name="subscribe" value="no" />
                  <input
                    type="checkbox"
                    name="subscribe"
                    value="yes"
                    {...register("subscribe", {
                      required: true,
                    })}
                  />
                  <span
                    className={`text-sm ${
                      errors.subscribe ? "text-red-500" : ""
                    }`}
                  >
                    I consent to receive updates about A.P.G Co.’s products and
                    services.
                  </span>
                </label>
              </div>
              <input type="hidden" name="_gotcha" className="!hidden" />
              <GoogleReCaptcha onVerify={onVerify} />
              <button
                className="font-sans text-newsletter transition-colors duration-200 ease-in-out hover:text-grey-base flex cursor-pointer items-center ml-auto"
                type="submit"
                disabled={serverState.submitting}
              >
                {serverState.submitting ? "Submitting" : "Submit"}
              </button>
              <p className="text-grey-base terms mt-4 md:max-w-[600px]">
                By submitting your enquiry, you indicate that you have read
                our&nbsp;
                <Link
                  className="underline hover:text-grey-base hover:no-underline"
                  to="/privacy"
                >
                  privacy collection notice
                </Link>
                &nbsp;and acknowledge that your personal information will be
                handled in accordance with that notice and our&nbsp;
                <a
                  className="underline hover:text-grey-base hover:no-underline"
                  target="_blank"
                  rel="noreferrer"
                  href="/Aria-Privacy-Policy.pdf"
                >
                  privacy policy
                </a>
              </p>
            </form>
            {serverState.status && !serverState.status.ok && (
              <p className="text-lg font-serif text-red-500">
                We're having an issue submitting form
              </p>
            )}
          </>
        )}
      </>
    </>
  );
};

export default Tennants;
